.structural-org {
    text-align: center;
  }
  
  .org-header {
    margin-bottom: 20px;
  }
  
  .org-chart-img {
    max-width: 100%;
    height: auto;
  }  