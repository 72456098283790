/* Main container styling */
.main-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

/* Header styling */
.calendar-header {
    text-align: center;
    padding-top: 5rem;
}

.calendar-header h3 {
    font-size: 24px;
    color: #0a4523;
}

/* Departments section styling */
.departments {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
}

.departments h4 {
    font-size: 20px;
    color: #0a4523;
    margin-bottom: 15px;
}

/* Department list styling */
.department-list {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.department-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f2f2f2;
}

.department-item:hover {
    background-color: #e0ffe0;
    border-color: #0a4523;
}

.department-name {
    font-size: 16px;
    color: #333;
}

.btn-link {
    font-size: 14px;
    color: #0a4523;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #0a4523;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
}

.btn-link:hover {
    background-color: #0a4523;
    color: #fff;
}
.subhead{
    padding-top: 5rem;
}
