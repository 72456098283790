.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.carousel {
    display: block;
    object-fit: cover;
    margin: auto;
    border-top: 4px solid #f0ba0a;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    width: 100%; 
    overflow: hidden; 
}
.carousel-item {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.carousel-item.active {
    opacity: 1;
  }
/* Ensure images inside the carousel cover the entire container */
.carousel img {
    width: 100%; /* Make images take the full width of the carousel */
    height: 100%; /* Make images take the full height of the carousel */
    object-fit: cover; /* Ensure images cover the container while maintaining aspect ratio */
}
.cover {
    padding: 4rem 1rem 0 1rem;
    background-color: #0a4523;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.cover-image {
    max-height: 30px;
    max-width: 60px;
    height: auto;
    width: auto;
    margin-right: 1rem;
}

.real-time {
    font-family: "Orbitron", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.25rem;
    color: #fff;
    text-align: right;
}

.logo {
    width: 100%; /* Full width initially */
    max-width: 600px;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .cover {
        flex-direction: column; /* Stack elements on smaller screens */
        align-items: center;
    }

    .cover-image {
        max-width: 40px;
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .realTime, .real-time {
        font-size: 1rem; /* Decrease font size */
        text-align: center;
    }

    .logo {
        max-width: 400px;
    }
}

@media (max-width: 480px) {
    .cover {
        padding: 1rem;
        margin-top: 3rem;
    }

    .realTime, .real-time {
        font-size: 0.875rem; /* Further decrease for smaller devices */
    }

    .logo {
        max-width: 300px;
    }

    .cover-image {
        max-width: 30px; /* Smaller image size for mobile */
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .carousel {
        max-height: 50vh; /* Adjust height for smaller screens */
    }
    
    .carousel-indicators [data-bs-target] {
        width: 10px; /* Smaller size for mobile */
        height: 10px;
    }
}

@media (max-width: 480px) {
    .carousel {
        max-height: 40vh; /* Further adjust height for very small screens */
    }
    
    .carousel-indicators [data-bs-target] {
        width: 8px; /* Even smaller size for very small screens */
        height: 8px;
    }
}
.carousel-placeholder {
    height: 500px; /* Adjust the height as needed */
    background: #f0f0f0; /* Light grey background color */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999; /* Light grey text color */
    font-size: 18px;
  }
  