/* Footer */
.footer {
    background-color: #0a4523;
    color: #ffffff;
    padding: 0rem 2rem;
    font-size: 15px;
    line-height: 1.6;
    border-top: 10px solid #f0ba0a; /* Adds a top border for a visual break */
}

.footer h4, .footer h5 {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 1rem;
    font-weight: 700;
}

.footer p, .footer a {
    color: #d3d3d3;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}

.footer p:hover, .footer a:hover {
    color: #ffffff;
}

.footer .map iframe {
    border: none;
    width: 100%;
    height: 230px;
    margin-bottom: 1.5rem;
    border-radius: 3px; /* Adds rounded corners to the map */
}

.footer .contact-info svg {
    fill: #ffffff;
    margin-right: 10px;
}

.footer .contact-info p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer .official-emails p {
    font-size: 14px;
}

.footer .social-icons a {
    color: #d3d3d3;
    margin-right: 10px;
    transition: color 0.3s ease;
}

.footer .social-icons a:hover {
    color: #ffffff;
}

.footer hr {
    border: 0;
    height: 2px;
    background: #ffffff;
    margin: 2rem 0;
}

.footer .copyright {
    text-align: center;
    font-size: 12px;
    color: #d3d3d3;
}
.copyright{
    text-align: center !important;
}
.contact{
    margin-top: -1rem;
}

/* Responsive Design for Footer */
@media screen and (max-width: 768px) {
    .footer {
        padding: 2rem 1rem;
    }

    .footer h4, .footer h5 {
        font-size: 18px;
    }

    .footer .map iframe {
        height: 200px;
    }

    .footer .contact-info, .footer .official-emails {
        text-align: center;
    }
}
