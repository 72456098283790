/* styles.css */
.my-4 {
  padding: 5rem 0;
  text-align: center;
  color: #0a4523;
  font-weight: bold;
}

.row-news {
  margin-top: -2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border: none;
  border-radius: 0.5px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ensures all images have the same size */
  border-bottom: 2px solid #ddd;
}

.card-body {
  padding: 1.5rem;
  text-align: center;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #0a4523;
  margin-bottom: 0.5rem;
}

.card-subtitle {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.read-more {
  font-size: 0.9rem;
  color: #0a4523;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #004494;
}

.btn-primary {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-primary:hover {
  background-color: #004494;
  border-color: #003d7a;
}
.loadMore {
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: -5rem;
}
/* styles.css */

/* Base styles remain the same */

@media (max-width: 980px) {
  .row-news {
    flex-direction: column; /* Stack cards vertically */
  }
  
  .card {
    margin: 1rem; /* Add margin between cards */
    width: 90%; /* Adjust card width */
  }

  .card-title {
    font-size: 1.1rem; /* Slightly smaller titles */
  }

  .card-text {
    font-size: 0.95rem; /* Smaller text */
  }
  .loadMore{
    margin-top: .5rem;
  }
}

@media (max-width: 480px) {
  .my-4 {
    padding: 3rem 0; /* Less padding */
  }

  .card {
    margin: 0.5rem; /* Tighter spacing */
  }

  .card-title {
    font-size: 1rem; /* Further reduce size */
  }

  .card-subtitle, .card-text {
    font-size: 0.8rem; /* Smaller font */
  }

  .read-more {
    font-size: 0.8rem; /* Adjust read-more size */
  }
}
