.admission-container {
    padding: 3rem 5rem 2rem 2rem;
}

.admission-req {
    padding: 0rem 5rem;
}

.ad-header {
    padding: 3rem;
    color: #0a4523;
    margin-left: 2rem !important;
}

.subhead-ad {
    text-align: center;
    padding-bottom: 1rem;
}

.table {
    font-size: 14px;
    width: 100%; /* Ensure the table takes up the full width */
    border-collapse: collapse; /* Improve table appearance */
}

.table thead {
    text-align: center;
    background-color: #f4f4f4; /* Add a background color for better readability */
    font-weight: bold; /* Make the header text bold */
}

.table th, .table td {
    padding: 8px; /* Add padding for better spacing */
    border: 1px solid #ddd; /* Add borders to table cells */
}

.units {
    text-align: center;
}

.header {
    margin-top: 5rem;
    text-align: left !important;
    margin-left: 5rem;
}

.main-header {
    margin-top: 5rem;
    text-align: left !important;
}

.my-1 {
    margin-left: .1rem !important;
}

.subhead {
    text-align: center;
}

.subtotal {
    text-align: right;
    font-weight: 500;
    font-size: 16px; /* Increase font size for emphasis */
    padding-right: 1rem; /* Add some padding to the right */
}

/* Responsive Styles */
@media (max-width: 780px) {
    .admission-container {
        padding: 2rem; /* Reduce padding */
    }

    .admission-req {
        padding: 0rem 2rem; /* Reduce side padding */
    }

    .ad-header {
        padding: 2rem;
        margin-left: 1rem !important; /* Adjust margin */
    }

    .header, .main-header {
        margin-left: 2rem; /* Adjust margin */
    }

    .table {
        font-size: 12px; /* Slightly reduce font size */
    }

    .table th, .table td {
        padding: 6px; /* Reduce cell padding */
    }
}

@media (max-width: 480px) {
    .admission-container {
        padding: 1rem; /* Further reduce padding */
        margin-top: 3rem;
    }

    .admission-req {
        padding: 0rem 1rem; /* Further reduce side padding */
    }

    .ad-header {
        padding: 1rem;
        margin-left: 0 !important; /* Remove margin */
    }

    .header, .main-header {
        margin-left: 0; /* Remove margin */
        text-align: center; /* Center align for small screens */
    }

    .table {
        font-size: 10px; /* Reduce font size further */
    }

    .table th, .table td {
        padding: 4px; /* Reduce cell padding further */
    }

    .subtotal {
        font-size: 14px; /* Adjust subtotal font size */
    }
}
