/* Header Styles */
h3 {
    color: #0a4523;
}
p {
    text-align: justify;
}
.header-container {
    color: #0a4523;
    padding: 7rem 15rem 2rem 0rem;
}

.header hr {
    border: 0;
    height: 2px;
    background: #333;
    margin-top: 0.5rem;
}

/* Content Styles */
.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: start;
    margin-bottom: 2rem;
}

.content .content-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.content .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #0a4523;
}

.content p {
    text-align: justify;
    margin-bottom: 1rem;
}

/* Table Styles */
.table {
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
}

.table th {
    background-color: #f4f4f4;
}

/* Values Section Styles */
.values {
    margin-top: 2rem;
    text-align: justify;
}

.values h5 {
    color: #0a4523;
    font-weight: bold;
}

.values h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.values table {
    font-size: 1.1rem;
    margin: 0 auto;
    width: 80%;
}

.values td {
    padding: 0.5rem;
    border: 1px solid #ddd;
}

.imgnursing {
    width: 100%;    
    height: 100%;
    border-radius: 8px;
    margin-top: -5rem;
}

/* Program Outcomes Section Styles */
.program-outcomes {
    margin-top: 1rem;
}

.program-outcomes h4 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

/* Responsive Styles */
@media screen and (max-width: 940px) {
    .content {
        grid-template-columns: 1fr;
    }

    .content img {
        margin-left: 0;
        margin-top: 1rem;
    }

    .values table {
        font-size: 1rem;
        width: 100%;
    }
}

@media screen and (max-width: 780px) {
    .header-container {
        padding: 4rem 5rem 2rem 1rem;
    }

    .content {
        padding: 1rem;
    }

    .values h3 {
        font-size: 1.5rem;
    }

    .program-outcomes h4 {
        font-size: 1.5rem;
    }

    .content .content-text {
        font-size: 0.9rem; /* Smaller font for content text */
    }
}

@media screen and (max-width: 480px) {
    .header-container {
        padding: 3rem 1rem 2rem 1rem;
        margin-top: 3rem;
    }

    .content {
        padding: 0.5rem;
    }

    .content img {
        margin-top: 0.5rem; /* Less margin for images */
    }

    .values h3 {
        font-size: 1.25rem; /* Smaller font size */
    }

    .program-outcomes h4 {
        font-size: 1.25rem; /* Smaller font size */
    }

    .table th,
    .table td {
        font-size: 0.9rem; /* Smaller font size for table */
    }

    .content .content-text {
        font-size: 0.85rem; /* Smaller font for content text */
    }
}

/* reveal */
.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.reveal {
    opacity: 1;
    transform: translateY(0);
}
