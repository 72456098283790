/* Font Styles */
.pt-serif-regular, .pt-serif-bold, .pt-serif-regular-italic, .pt-serif-bold-italic {
    font-family: "PT Serif", serif;
}

.pt-serif-regular {
    font-weight: 400;
    font-style: normal;
}

.pt-serif-bold {
    font-weight: 700;
    font-style: normal;
}

.pt-serif-regular-italic {
    font-weight: 400;
    font-style: italic;
}

.pt-serif-bold-italic {
    font-weight: 700;
    font-style: italic;
}

/* Navbar */
.navbar {
    background-color: #0a4523 !important;
    opacity: 1; /* Use 1 for 100% opacity */
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.377);
}

.nav-link, .dropdown-item, .navbar-brand {
    color: #fcfcfc !important;
    background-color: #0a4523 !important;
}

.nav-link:hover, .dropdown-item:hover {
    color: #9ea0a0 !important;
    text-decoration: none;
}

/* About Section */
.about {
    margin: auto;
}
.home-about{
    border: none !important;
}

.about .container {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about img {
    height: 400px;
}

.about h2 {
    font-size: 3rem;
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}

.about .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.about button {
    margin-top: 1rem;
}

/* VMGO Section */
#vmgo img {
    height: 300px;
    margin-bottom: 2rem;
}

/* Announcement Section */
#announcement {
    margin-left: 5rem;
    margin-bottom: 5rem;
}

#announcement img {
    width: 200px;
    height: 100px;
}

/* Header Update */
.header-update {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-decoration: underline;
    text-decoration-color: #0a4523;
    text-decoration-thickness: 2px;
    color: #0a4523;
}

/* Header */
.header {
    text-align: justify;
    margin-bottom: 2rem;
}

/* History Section */
.history {
    margin: 0;
    text-align: justify;
}

.history header {
    text-align: left;
}

/* Images and Iframes */
#imgnursing {
    height: 300px;
}

iframe {
    height: 300px;
    width: 450px;
}

/* Parallax Section */
.parallax {
    background-image: url("../img/rtrbldg.jpg");
    height: 100vh; /* Full viewport height */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; /* Ensure the image covers the entire area */
    display: flex; /* Use flexbox for centering content */
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    overflow: hidden; /* Prevent overflow */
}

/* Section Background */
.section-bg.style-1 {
    background-color: transparent; /* Ensure no extra background color is applied */
    padding: 0; /* Remove padding to avoid extra space */
    text-align: center;
    position: relative; /* Ensure positioning is relative for child elements */
}

/* Container */
.section-bg .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative; /* Position relative to ensure correct alignment */
}

/* Row */
.section-bg .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; /* Position relative to ensure correct alignment */
}

/* Heading */
.section-bg .row h1 {
    width: 100%;
    font-size: 36px;
    font-weight: 700;
    color: white; /* Ensure heading text is visible on parallax background */
    margin-bottom: 40px;
}

/* Column */
.section-bg .col-lg-4 {
    flex: 0 0 32%;
    max-width: 32%;
    box-sizing: border-box;
    margin-bottom: 30px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.section-bg .col-lg-4:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Icons */
.section-bg .icon {
    font-size: 40px;
    color: white; /* Ensure icon color is visible on parallax background */
    margin-bottom: 20px;
    transition: color 0.3s;
}

.section-bg .icon:hover {
    color: #1d7d6e; /* Darker shade on hover */
}

/* Column Headings */
.section-bg h3 {
    font-size: 24px;
    font-weight: 700;
    color: white; /* Set text color to white */
    margin-bottom: 0;
    transition: color 0.3s;
}

.section-bg h3:hover {
    color: #2a9d8f; /* Change color on hover, adjust as needed */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .section-bg .col-lg-4 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
    }
}


/* Responsive Design for About Section */
@media screen and (max-width: 940px) {
    .about .container {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .about img {
        width: 90vw;
        height: 30vh;
    }

    .about .col-2 {
        padding: 0;
    }

    .about button {
        margin-bottom: 1rem;
    }
}

.gtco-aboutus-four {
    padding: 50px 0;
    background-color: #f8f9fa;
    text-align: center;
    
  }
  
  .gtco-aboutus-four h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  
  .core-value-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .core-value-box {
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .core-value-box .icon {
    display: block;
    margin: 0 auto 20px;
  }
  
  .core-value-box img {
    max-width: 80px;
    height: auto;
  }
  
  .core-value-box h4 {
    font-size: 24px;
    color: #0a4523;
    margin-bottom: 15px;
  }
  
  .core-value-box p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }
  
  .core-value-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Animation classes */
  .animatedParent {
    opacity: 0;
    visibility: hidden;
  }
  
  .animated {
    opacity: 0;
    visibility: hidden;
  }
  
  .animatedParent.go .animated {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 1;
    visibility: visible;
  }
  
  .growIn {
    animation-name: growIn;
  }
  
  @keyframes growIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .core-value-box {
      margin-bottom: 20px;
      padding: 15px;
    }
  
    .core-value-box h4 {
      font-size: 20px;
    }
  
    .core-value-box p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .core-value-container {
      flex-direction: column;
      align-items: center;
    }
  
    .core-value-box {
      width: 90%;
    }
  }
  
  .core-values-section {
    padding: 60px 0;
    background-color: #f9f9f9; /* Background color for the section */
  }
  
  .core-values-section .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .core-values-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0a4523;
    margin-bottom: 40px;
  }
  
  .core-values-section .row {
    display: flex;
    justify-content: space-around; /* Distributes columns evenly */
    flex-wrap: wrap;
  }
  
  .core-value-box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the image horizontally */
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  .core-value-box:hover {
    transform: translateY(-5px); /* Lifts the box slightly on hover */
  }
  
  .core-value-box img {
    max-width: 200px; /* Restricts the image size */
    margin-bottom: 20px;
  }
  
  .core-value-box h4 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #0a4523; /* Primary color */
    margin-bottom: 15px;
  }
  
  .core-value-box p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .core-values-section h2 {
      font-size: 2rem;
    }
  
    .core-value-box {
      padding: 15px;
    }
  
    .core-value-box img {
      max-width: 70px;
    }
  
    .core-value-box h4 {
      font-size: 1.125rem;
    }
  
    .core-value-box p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 992px) {
    .core-values-section h2 {
      font-size: 1.75rem;
    }
  
    .core-value-box {
      padding: 10px;
    }
  
    .core-value-box img {
      max-width: 60px;
    }
  
    .core-value-box h4 {
      font-size: 1rem;
    }
  
    .core-value-box p {
      font-size: 0.875rem;
    }
  
    .core-value-box {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .core-value-box {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .core-values-section h2 {
      font-size: 1.5rem;
    }
  
    .core-value-box {
      padding: 8px;
    }
  
    .core-value-box img {
      max-width: 50px;
    }
  
    .core-value-box h4 {
      font-size: 0.875rem;
    }
  
    .core-value-box p {
      font-size: 0.75rem;
    }
  }
  
/* Initial state before scroll */
.scroll-reveal {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* State when element is in view */
.scroll-reveal-active {
  opacity: 1;
  transform: translateY(0);
}