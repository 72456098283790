/* AboutDetails.css */

/* History Styles */
.history-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffff;
}

.history-about, .history {
  padding: 1rem;
  border-radius: 12px;
}

/* Image Section */
.image-container {
  margin: 0 auto 2rem;
}

.image-container img {
  width: 160vh;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  transition: transform 0.3s ease-in-out;
}

.image-container img:hover {
  transform: scale(1.05);
}

/* History Content */
.history p:last-child {
  margin-bottom: 0;
}

.history-details {
  padding: 0 1rem;
}

.history-header {
  color: #0a4523;
  border-bottom: 2px solid #0a4523;
  padding-top: 2.5rem;
}

.hist-drtr {
  padding: 2rem;
  text-align: center;
  color: #0a4523;
}

.history-header h1 {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .history-container {
    padding: 1.5rem;
  }

  .history-header {
    padding-top: 2rem;
  }

  .hist-drtr {
    padding: 1.5rem;
  }

  .image-container img {
    width: 120vh; /* Adjusted width for tablet screens */
  }
}

@media (max-width: 768px) {
  .history-container {
    padding: 1rem;
  }

  .history-header {
    padding-top: 1.5rem;
  }

  .hist-drtr {
    padding: 1rem;
  }

  .image-container {
    margin-bottom: 1.5rem;
  }

  .image-container img {
    width: 90vh; /* Adjusted width for mobile screens */
  }

  .history-header h1 {
    font-size: 1.8rem;
  }

  .text-justify {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .history-container {
    padding: 0.5rem;
    margin-top: 3rem;
  }

  .history-header {
    padding-top: 1rem;
  }

  .hist-drtr {
    padding: 0.5rem;
  }

  .image-container img {
    width: 100%; /* Full width for small mobile screens */
  }

  .history-header h1 {
    font-size: 1.5rem;
  }

  .text-justify {
    font-size: 0.85rem;
  }
}


/* About Design */
.about-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
}

.about-header {
  margin-bottom: 2rem;
  
}

.about-header h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #0a4523;
}

.about-content .row {
  margin-bottom: 2rem;
}

.about-section h3 {
  font-size: 1.5rem;
  color: #0a4523;
  margin-bottom: 1rem;
}

.about-section ul {
  list-style-type: none;
  padding-left: 0;
}

.about-section li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.about-section li:before {
  position: absolute;
  left: 0;
  color: #0a4523;
  font-size: 1.2rem;
}

.about-image {
  max-width: 80%;
  transition: transform 0.3s ease;
}


/* Reveal animation when element comes into view */
.scroll-reveal.revealed {
  opacity: 1;
  transform: translateY(0);
}