/* Journal.css */

/* Container */
.about-container {
  margin: 0 auto;
  padding: 6rem;
  line-height: 1.6;
  background-color: #fff;
}

/* Headings */
.about-container h1 {
  text-align: center;
  color: #0a4523;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-container h2 {
  color: #0a4523;
  font-size: 2rem;
  text-align: left;
  margin-bottom: 10px;
  /* border-bottom: 2px solid #0a4523; */
  font-weight: bold;
  padding-bottom: 5px;
}

/* Paragraphs */
.about-container p {
  margin-bottom: 15px;
  color: #333;
  font-size: 1rem;
}

/* Sections */
.about-section {
  margin-bottom: 40px;
}

/* Lists */
.about-section ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #333;
}

.about-section ul li {
  margin-bottom: 10px;
}

/* Image */
.about-section img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  border: 3px solid #0a4523;
  border-radius: 8px;
}

/* Figure */
.about-section figure {
  text-align: center;
  margin: 20px 0;
}

.about-section figcaption {
  font-style: italic;
  color: #555;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 980px) {
  .about-container {
      padding: 4rem;
  }

  .about-container h1 {
      font-size: 2.2rem;
  }

  .about-container h2 {
      font-size: 1.75rem;
  }

  .about-container p {
      font-size: 0.95rem;
  }

  .about-section ul li {
      font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .about-container h1 {
      font-size: 2rem;
  }

  .about-container h2 {
      font-size: 1.5rem;
  }

  .about-container p {
      font-size: 0.9rem;
  }

  .about-section ul li {
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {

  .about-container h1 {
      font-size: 1.8rem;
  }

  .about-container h2 {
      font-size: 1.25rem;
  }

  .about-container p {
      font-size: 0.85rem;
  }

  .about-section ul li {
      font-size: 0.85rem;
  }
}
