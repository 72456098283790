.ejournal-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem;
  color: #333;
}

.about {
  margin-bottom: 40px;
}
.header .title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #0a4523;
}

.divider {
  border-top: 2px solid #0a4523;
  width: 60%;
  margin: 0 auto;
}

.section-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #444;
  text-align: left;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.section-text {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

.volumes {
  margin-top: 40px;
}

.jcontent {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adds space between items */
  justify-content: center; /* Centers the items horizontally */
}

.content-img {
  flex: 1 1 calc(33.333% - 20px); /* Adjust to fit three items per row with gap */
  max-width: calc(33.333% - 20px);
  text-align: center;
  margin-bottom: 30px;
}

.journal-cover {
  max-width: 100%; /* Ensures the image does not overflow its container */
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.journal-cover:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.volume-info {
  font-size: 0.9rem;
  margin-top: 10px;
  color: #555;
}