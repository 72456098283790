.hero {
  color: #fff;
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.6); /* Highlight effect */
  transition: box-shadow 0.3s ease; /* Smooth transition */

}
.hero a {
  color: #ffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

/* .hero a:hover {
  color: #f0ba0a;
} */
.hero .box {
  position: relative;
  padding: 30px;
  max-height: 100vh;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.hero .box:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.3s ease;
  opacity: 0.9;
  
  
}
.hero .box:hover img {
  transform: scale(1.05);
  opacity: 1;
  
}
.hero .container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(12, 6vh);
  grid-gap: 20px;
  
}
.hero .box:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 9;
}
.hero .box:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}
.hero .box:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 9;
}
.hero .box:nth-child(4) {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 9;
}
.hero .box:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 9;
  grid-row-end: 12;
}
.hero .box:nth-child(6) {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 12;
}
.hero .box:nth-child(7) {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 9;
  grid-row-end: 12;
}
.hero .box:nth-child(8) {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 9;
  grid-row-end: 12;
}
.hero .text {
  position: absolute;
  bottom: 1px;
  padding-left: 15px;
  left: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.397);
  height: 100px; /* Adjust the height value as needed */
}

.hero .box:nth-child(2) .text,
.hero .box:nth-child(3) .text,
.hero .box:nth-child(4) .text,
.hero .box:nth-child(5) .text,
.hero .box:nth-child(6) .text,
.hero .box:nth-child(7) .text,
.hero .box:nth-child(8) .text {
  position: absolute;
  bottom: 10px; /* Lower the text for these children */
  left: 1px;
  width: 100%;
  font-size: 12px;
}
.hero .text h1 {
  font-weight: 700;
  margin: 15px 0;
  font-size: 18px;
  color: #ffff !important;
}
.hero .author span {
  font-size: 14px;
  margin-right: 20px;
  color: #666;
}
.hero .box:nth-child(1) h1 {
  font-size: 28px;
}
.hero .box:nth-child(1) .category {
  background-color: #ffa500;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff ;
}
.hero .box:nth-child(2) .category {
  background-color: #ffa500;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.hero .box:nth-child(4) .category {
  background-color: #ffa500;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.hero .box:nth-child(5) .category {
  background-color: #ffa500;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.hero .box:nth-child(6) .category {
  background-color: #8a2be2;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.hero .box:nth-child(7) .category {
  background-color: #ff4500;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.hero .box:nth-child(8) .category {
  background-color: #20b2aa;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .hero .container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(30, 6vh);
    grid-gap: 15px;
  }
  .hero .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .hero .box:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 10;
  }
  .hero .box:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 15;
  }
  .hero .box:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
  }
  .hero .box:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 20;
    grid-row-end: 24;
  }
  .hero .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 24;
    grid-row-end: 27;
  }
  .hero .box:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 27;
    grid-row-end: 30;
  }
  .hero .box:nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 30;
    grid-row-end: 33;
  }
}
.loadMore {
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: -5rem;
}