.extension-container {
    margin: 0 auto;
    padding: 5rem;
    line-height: 1.6;
  }
  
  .extension-container h1, h2 {
    color: #0a4523;
    text-align: center;
  }
  
  .extension-h3 {
    color: #0a4523;
    border-bottom: 2px solid #0a4523;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  p {
    margin: 10px 0;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  strong {
    color: #0a4523;
  }
  .short-term-agenda, .extension-programs {
    margin-bottom: 30px;
  }
  
  .short-term-agenda ul, .extension-programs ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .extension-programs ul li {
    margin-bottom: 15px;
  }
  
  h4 {
    color: #0a4523;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  strong {
    color: #0a4523;
  }
  /* ExtensionServicesActivities.css */

/* Header Section */
.header {
  text-align: center;
  padding: 20px;
}

.header h1 {
  font-size: 36px;
  margin: 0;
}

.header p {
  font-size: 18px;
}

/* Activities Section */
.activities {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.activity-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.activity-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.activity-title {
  font-size: 24px;
  margin: 10px 0;
}

.activity-date {
  font-size: 16px;
  color: #777;
}

.activity-description {
  font-size: 16px;
}

.activity-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.activity-link:hover {
  text-decoration: underline;
}

/* Upcoming and Past Activities Sections */
.upcoming-events, .past-activities {
  background: #f1f1f1;
  padding: 20px;
  margin: 20px 0;
}

.upcoming-events h2, .past-activities h2 {
  font-size: 28px;
}

/* Contact Info Section */
.contact-info {
  text-align: center;
  padding: 20px;
  background: #e9ecef;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
/* Extension.css */

.extension-activities {
    margin: 0 auto;
    padding: 5rem;
    max-width: 1200px;
  }
  
  .extension-activities_header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .extension-activities_title {
    font-size: 2.5em;
    margin: 0;
    color: #333;
  }
  
  .extension-activities_description {
    font-size: 1.2em;
    color: #666;
  }
  
  .extension-activities_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .extension-activities_card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .extension-activities_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .extension-activities_image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .extension-activities_card-title {
    font-size: 1.5em;
    margin: 15px 10px 5px;
    color: #333;
  }
  
  .extension-activities_card-date {
    font-size: 1em;
    margin: 0 10px 10px;
    color: #999;
  }
  
  .extension-activities_card-description {
    font-size: 1em;
    margin: 0 10px 15px;
    color: #555;
  }
  
  .extension-activities_card-link {
    display: block;
    text-align: center;
    padding: 10px;
    background-color: #0a4523;
    color: #fff;
    text-decoration: none;
    border-radius: 0 0 8px 8px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .extension-activities_card-link:hover {
    background-color: #083a1e;
  }
  
  .extension-activities_upcoming,
  .extension-activities_past {
    margin-top: 40px;
  }
  
  .extension-activities_section-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  .activity-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    transition: transform 0.2s;
  }
  
  .activity-card:hover {
    transform: scale(1.02);
  }
  
  .activity-card_image {
    width: 100%;
    height: auto;
  }
  
  .activity-card_content {
    padding: 15px;
  }
  
  .activity-card_title {
    font-size: 1.25em;
    margin-bottom: 10px;
  }
  
  .activity-card_date {
    font-size: 0.9em;
    color: #888;
    margin-bottom: 10px;
  }
  
  .activity-card_description {
    font-size: 1em;
    margin-bottom: 15px;
  }
  
  .activity-card_link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .activity-card_link:hover {
    text-decoration: underline;
  }
  
  
  .album {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
  }
  
  .photoItem {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    text-align: center;
  }
  