main {
    padding: 5rem;
    text-align: justify;
  }
  .details {
    padding-right: 50px;
  }
  .details .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
  }
  .details .author {
    display: flex;
    align-items: center;
    font-weight: 500;
    color:#0a4523;
  }
  .details .author p {
    text-transform: capitalize;
    margin-right: 10px;
  }
  .main-img {
    width: 100%;
    height: 80vh;
    object-fit: contain; /* Ensures the entire image fits without distortion */
    margin: 0 10px;
    border-radius: 1px;
    display: block;
    max-width: 100%;
    max-height: 80vh;
}
  .details .author label {
    color: grey;
  }
  .details .social {
    display: flex;
    margin-bottom: 20px;
  }
  .details .social .socBox {
    margin-right: 20px;
  }
  .details img {
    margin: 30px 0;
  }
  h1{
    color: #0a4523;
  }
  @media (max-width: 980px) {
    .details .title {
      font-size: 32px;
      line-height: 48px;
    }
    .details {
      padding-right: 20px;
    }
    .main-img{
      margin-top: -5rem;
    }
  }
  
  @media (max-width: 480px) {
    .main-img {
      width: 100%;
      height: 50vh;
    }
    .details .title {
      font-size: 24px;
      line-height: 36px;
    }
    .details .author {
      flex-direction: column;
      align-items: flex-start;
    }
    .details .social {
      flex-direction: column;
    }
  }
  