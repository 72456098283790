/* General Styles */
.main-content {
  width: 100%;
  margin: auto;
  padding: 5rem;
}
.title-journal{
  text-align: justify;
  color: #0a4523;
  margin-bottom: 1rem;
  text-transform: capitalize !important;
}
.main-content-header{
  color: #0a4523;
  text-align: center;
  margin-bottom: 1rem;
}
.journal-header {
  text-align: left;
  color: #0a4523;
}

.journal-header h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.journal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Adjust width for responsiveness */
}

/* .post-box {
  margin-bottom: 20px;
} */

.post {
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* transition: transform 0.2s ease, box-shadow 0.2s ease; */
}

/* .post:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
} */

.title-journal a{
  font-size: 1.5rem;
  color: #2c8150;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.2s ease;
  text-align: left !important;
}

.title-journal a:hover {
  color: #0a4523;
}

.entry-meta {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
}

.entry-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  text-align: justify;
}

.moretag {
  color: #007bff;
  text-decoration: none;
}

.moretag:hover {
  text-decoration: underline;
}

/* Journal Detail Styles */
.journal-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem;
  text-align: justify;
}

.journal-meta {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 1.25rem;
}

.journal-content {
  line-height: 1.6;
}

.pdf {
  margin-top: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .main-content {
    padding: 3rem;
  }

  .journal-detail {
    padding: 3rem;
  }

  .journal-header h3 {
    font-size: 1.25rem;
  }

  .entry-title a {
    font-size: 1.25rem;
  }

  .entry-content p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 2rem;
  }

  .journal-detail {
    padding: 2rem;
  }

  .journal-header h3 {
    font-size: 1rem;
  }

  .entry-title a {
    font-size: 1rem;
  }

  .entry-content p {
    font-size: 0.875rem;
  }

  .journal-content {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 1rem;
  }

  .journal-detail {
    padding: 1rem;
  }

  .journal-header h3 {
    font-size: 0.875rem;
  }

  .entry-title a {
    font-size: 0.875rem;
  }

  .entry-content p {
    font-size: 0.75rem;
  }

  .journal-content {
    gap: 10px;
  }
}
