.research-org-container {
    text-align: center;
    padding: 5rem;
  }
  
  .org-header {
    margin-bottom: 20px;
    color: #0a4523;
    text-decoration: solid;
  }
  
  .org-chart-img {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  
  .loading-text,
  .error-text {
    color: #888;
    font-size: 16px;
  }
  